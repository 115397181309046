// src/components/Register.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: ''
  });
  const [message, setMessage] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Initialize useNavigate
  useEffect(() => {
    // Fetch roles from the backend
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/roles/`); // Adjust the URL if needed
        setRoles(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching roles:', err);
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        role: formData.role,
      });
      setMessage(res.data.msg || 'Registration successful');

      // Navigate to the login page after successful registration
      navigate('/login');
    } catch (err) {
      setMessage('Registration failed');
    }
  };

  return (
    <div className="d-flex vh-100">
      <div className="card p-4 shadow-lg" style={{ width: '100%' }}>
        <h3 className="text-center mb-4">Add New User</h3>

        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3 row">
            <label htmlFor="username" className="form-label col-sm-3">Username</label>
            <div className="col-sm-9">
            <input
              type="text"
              name="username"
              className="form-control"
              id="username"
              placeholder="Enter your username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            </div>
          </div>

          <div className="form-group mb-3 row">
            <label htmlFor="email" className="form-label col-sm-3 col-sm-3">Email address</label>
            <div className="col-sm-9">
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          </div>

          <div className="form-group mb-3 row">
            <label htmlFor="password" className="form-label col-sm-3">Password</label>
            <div className="col-sm-9">
            <input
              type="password"
              name="password"
              className="form-control"
              id="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          </div>

          <div className="form-group mb-4 row">
            <label htmlFor="confirmPassword" className="form-label col-sm-3">Confirm Password</label>
            <div className="col-sm-9">
            <input
              type="password"
              name="confirmPassword"
              className="form-control"
              id="confirmPassword"
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          </div>
          <div className="form-group mb-4 row">
          <label htmlFor="role" className="form-label col-sm-3">Role</label>
          <div className="col-sm-9"> <select
            name="role"
            className="form-control"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role._id} value={role.name}>
                {role.roleName.charAt(0).toUpperCase() + role.roleName.slice(1)}
              </option>
            ))}
          </select>
          </div>
        </div>
          <div className="form-group mb-4 row">
            <div className='col-sm-3'>
          <button type="submit" className="btn btn-primary w-100 mb-3 btn-md" style={{width:'150px'}}>Save User</button>
          </div>
          </div>

          {message && (
            <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-danger'}`} role="alert">
              {message}
            </div>
          )}

        </form>
      </div>
    </div>
  );
};

export default Register;
