// src/components/FormBuilderView.js
import React, { useState } from 'react';
import { FormBuilder } from 'react-formio';
import { saveFormToLocal } from '../utils/formUtils';

const FormBuilderView = () => {
  const [formSchema, setFormSchema] = useState({});

  const handleFormChange = (schema) => {
    setFormSchema(schema);
  };

  const handleSave = () => {
    saveFormToLocal(formSchema);
    alert('Form saved successfully!');
  };

  return (
    <div className="row">
      <h2>Form Builder</h2>
      <FormBuilder form={formSchema} onChange={handleFormChange} />
      <button className="btn btn-primary mt-3 btn-md" onClick={handleSave}>
        Save Form
      </button>
    </div>
  );
};

export default FormBuilderView;
