// src/components/FormRenderer.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-formio';
import { getFormsFromLocal, saveFormSubmissionToLocal  } from '../utils/formUtils';

const FormRenderer = () => {
  const { index } = useParams(); // Get the form index from URL parameters
  const forms = getFormsFromLocal();
  const formSchema = forms[index]; // Get the specific form schema by index

  if (!formSchema) {
    return <div>No form found.</div>;
  }

  const handleSubmit = (submission) => {
    saveFormSubmissionToLocal(index, submission); // Save submission to localStorage
    console.log('Form Submission:', submission);
    alert('Form submitted successfully!');
  };

  return (
    <div className="container">
      <h2>Render Form</h2>
      <Form form={formSchema} onSubmit={handleSubmit} />
    </div>
  );
};

export default FormRenderer;
