// src/components/EditFormBuilder.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormBuilder } from 'react-formio';
import { getFormsFromLocal, updateFormInLocal } from '../utils/formUtils';

const EditFormBuilder = () => {
  const { index } = useParams(); // Get form index from URL parameters
  const [formSchema, setFormSchema] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const savedForms = getFormsFromLocal();
    if (savedForms[index]) {
      setFormSchema(savedForms[index]); // Load the form schema into the builder
    }
  }, [index]);

  const handleFormChange = (schema) => {
    setFormSchema(schema);
  };

  const handleSave = () => {
    updateFormInLocal(index, formSchema); // Update the form in localStorage
    alert('Form updated successfully!');
    navigate('/forms'); // Redirect back to the forms list after saving
  };

  return (
    <div className="container">
      <h2>Edit Form</h2>
      <FormBuilder form={formSchema} onChange={handleFormChange} />
      <button className="btn btn-primary mt-3" onClick={handleSave}>
        Save Changes
      </button>
    </div>
  );
};

export default EditFormBuilder;