// src/utils/formUtils.js

// Save a form schema to local storage
export const saveFormToLocal = (formSchema) => {
    let forms = JSON.parse(localStorage.getItem('forms')) || [];
    forms.push(formSchema);
    localStorage.setItem('forms', JSON.stringify(forms));
  };
  
  // Get all forms from local storage
  export const getFormsFromLocal = () => {
    return JSON.parse(localStorage.getItem('forms')) || [];
  };

  // Update a specific form in local storage
export const updateFormInLocal = (index, updatedFormSchema) => {
    let forms = JSON.parse(localStorage.getItem('forms')) || [];
    forms[index] = updatedFormSchema;
    localStorage.setItem('forms', JSON.stringify(forms));
  };

  // Save a form submission to local storage
export const saveFormSubmissionToLocal = (formIndex, submission) => {
    let submissions = JSON.parse(localStorage.getItem('submissions')) || {};
    if (!submissions[formIndex]) {
      submissions[formIndex] = [];
    }
    submissions[formIndex].push(submission);
    localStorage.setItem('submissions', JSON.stringify(submissions));
  };
  
  // Get form submissions from local storage
  export const getFormSubmissionsFromLocal = (formIndex) => {
    let submissions = JSON.parse(localStorage.getItem('submissions')) || {};
    return submissions[formIndex] || [];
  };

  export const deleteFormFromLocal = (formIndex) => {
    let forms = JSON.parse(localStorage.getItem('forms')) || [];
    forms.splice(formIndex, 1); // Remove the form from the array
    localStorage.setItem('forms', JSON.stringify(forms));
  
    // Optionally delete the associated submissions as well
    let submissions = JSON.parse(localStorage.getItem('submissions')) || {};
    delete submissions[formIndex]; // Remove submissions related to the deleted form
    localStorage.setItem('submissions', JSON.stringify(submissions));
  };