import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';


function OrganisationList() {
  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(true);
  const images = require.context('../images', false, /\.(png|jpe?g|svg)$/);

  // Fetch data from the server
  useEffect(() => {
    const fetchOrganisations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/organisations`); // Replace with your API URL
        const specificIds = ['372', '374', '367', '368', '370', '377'];
        const filteredOrganisations = response.data.filter(org => specificIds.includes(org.id));
        setOrganisations(filteredOrganisations);        
      } catch (error) {
        console.error("Error fetching organisations:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrganisations();
  }, []);

  // Define columns for DataTable
  const columns = [
    { name: 'ID', selector: row => row.id, sortable: true },
    {
      name: 'Logo',
      selector: row => row.logo,
      cell: row => (
        <img
        src={row.logo ? `/images/${row.logo}` : '/images/org_logo1.png'} // Use default if logo is absent
          alt={row.name}
          style={{ width: '50px', height: '50px', borderRadius: '4px' }}
        />
      ),
      width: '80px',
      ignoreRowClick: true,
      allowoverflow: true,
      sortable: false,
    },
    
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Short Name', selector: row => row.short_name, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Phone', selector: row => row.phone1, sortable: true },
    { name: 'Country ID', selector: row => row.country_id, sortable: true },
    { name: 'City', selector: row => row.city, sortable: true },
    { name: 'Active', selector: row => row.active ? 'Yes' : 'No', sortable: true },
    // Add more columns as needed
  ];
  return (
    <div className="container mt-5">
      <h2>Delivery Partners</h2>
      <DataTable
        columns={columns}
        data={organisations}
        progressPending={loading}
        pagination
        highlightOnHover
        striped
        responsive
      />
    </div>
  );
}

export default OrganisationList;
