// src/components/FormsList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFormsFromLocal, deleteFormFromLocal  } from '../utils/formUtils';

const FormsList = () => {
  const [forms, setForms] = useState([]);

  useEffect(() => {
    const savedForms = getFormsFromLocal();
    setForms(savedForms);
  }, []);

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this form?")) {
      deleteFormFromLocal(index); // Delete form from local storage
      const updatedForms = getFormsFromLocal();
      setForms(updatedForms); // Update the state to reflect the deletion
    }
  };

  return (
    <div className="container">
      <h2>Saved Forms</h2>
      {forms.length === 0 ? (
        <p>No forms found.</p>
      ) : (
        <ul>
          {forms.map((form, index) => (
            <li key={index}>
              <Link to={`/forms/${index}`}>View Form {index + 1}</Link>
              {" | "}
              <Link to={`/edit-form/${index}`}>Edit Form {index + 1}</Link>
              {" | "}
              <Link to={`/submissions/${index}`}>View Submissions</Link>
              <button
                onClick={() => handleDelete(index)}
                className="btn btn-danger btn-sm"
                style={{ marginLeft: '10px' }}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormsList;
