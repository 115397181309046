// src/components/Login.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../images/logo.png';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');

  const navigate = useNavigate(); // Initialize useNavigate 
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the token exists in localStorage
    const token = localStorage.getItem('token');
    
    if (token) {
      setIsLoggedIn(true);
      navigate('/');
      
    } else {
      setIsLoggedIn(false);     
    }
  }, []);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, formData);
      console.log(res.data);
      const { token, username } = res.data;  // Assuming username is part of the response
      localStorage.setItem('token', token);  // Store JWT token in localStorage
      localStorage.setItem('username', username);  // Store username in localStorage
      setMessage('Login successful');

      // Navigate to the homepage after successful login
     navigate('/');
    } catch (err) {
      setMessage('Invalid credentials');
    }
  };

  return (<div className="login-page bk-img">
    <div className="form-content">
        <div className="container">
            <div className="row d-flex align-items-center justify-content-center vh-100">
                <div className="col-md-6 col-md-offset-3 login-box">
                    <h1 className="text-center pt-2x"><img src={ logo } width="150" /></h1>
                    <h1 className="text-center text-bold">Admin Login</h1>
                    <div className="well row pt-2x pb-3x bk-light align-items-center justify-content-center">
                        <div className="col-md-8 col-md-offset-2 ">
                             <div id="message-wrap">
      <span></span>
    </div>
    <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="email" className="form-label">Email address</label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mb-4">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              id="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit" className="btn btn-primary btn-block">Login</button>

          {message && (
            <div className={`alert ${message === 'Login successful' ? 'alert-success' : 'alert-danger'}`} role="alert">
              {message}
            </div>
          )}

          {/* <p className="text-center">
            Don't have an account? <a href="/register">Sign Up</a>
          </p> */}
        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
};

export default Login;
