// frontend/src/components/OrganizationForm.js
import React, { useState } from 'react';
import { Tab, Tabs, Form, Button, Col, Row, Image } from 'react-bootstrap';
import axios from 'axios';

function OrganizationForm() {
  const [profile, setProfile] = useState({
    logo: null,
    logoPreview: null,  // For displaying logo preview
    organisationType: '',
    name: '',
    shortName: '',
    active: false,
    yearFounded: '',
    yearIGFAffiliation: '',
    website: '',
    comments: '',
    websiteDescription: '',
  });
  const [address, setAddress] = useState({
    landline: '',
    mobile: '',
    email1: '',
    email2: '',
    addressLine1: '',
    addressLine2: '',
    fax: '',
    city: '',
    zipcode: '',
    state: '',
    country: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!profile.organisationType) newErrors.organisationType = 'Organization type is required';
    if (!profile.name) newErrors.name = 'Name is required';
    if (!profile.shortName) newErrors.shortName = 'Short name is required';
    if (!profile.yearFounded) newErrors.yearFounded = 'Year founded is required';
    if (!address.email1) newErrors.email1 = 'Primary email is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfile({
        ...profile,
        logo: file,
        logoPreview: URL.createObjectURL(file)  // Set the logo preview URL
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append('logo', profile.logo);
    formData.append('profile', JSON.stringify(profile));
    formData.append('address', JSON.stringify(address));

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/organizations`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      alert('Organization added successfully');
    } catch (err) {
      console.error(err);
      alert('Error adding organization');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="profile">
        {/* Profile Tab */}
        <Tab eventKey="profile" title="Profile">
          <Form.Group as={Row} className="my-3">
            <Form.Label column sm="2" className="mb-4">Logo</Form.Label>
            <Col sm="4">
              <Form.Control type="file" className="form-control" onChange={handleFileChange} />
            </Col>
            <Col sm="2" className="mb-4">
              {profile.logoPreview && (
                <Image src={profile.logoPreview} thumbnail width="100" />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Organization Type</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="text"
                className="form-control"
                value={profile.organisationType}
                onChange={(e) => setProfile({ ...profile, organisationType: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Name</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="text"
                className="form-control"
                value={profile.name}
                onChange={(e) => setProfile({ ...profile, name: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Short Name</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="text"
                className="form-control"
                value={profile.shortName}
                onChange={(e) => setProfile({ ...profile, shortName: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Active</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Check
                type="checkbox"
                className="form-control"
                checked={profile.active}
                onChange={(e) => setProfile({ ...profile, active: e.target.checked })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Year Founded</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="number"
                className="form-control"
                value={profile.yearFounded}
                onChange={(e) => setProfile({ ...profile, yearFounded: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Year IGF Affiliation</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="number"
                className="form-control"
                value={profile.yearIGFAffiliation}
                onChange={(e) => setProfile({ ...profile, yearIGFAffiliation: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Website</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="url"
                className="form-control"
                value={profile.website}
                onChange={(e) => setProfile({ ...profile, website: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Comments</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                as="textarea"
                rows={3}
                className="form-control"
                value={profile.comments}
                onChange={(e) => setProfile({ ...profile, comments: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Website Description</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                as="textarea"
                rows={3}
                className="form-control"
                value={profile.websiteDescription}
                onChange={(e) => setProfile({ ...profile, websiteDescription: e.target.value })}
              />
            </Col>
          </Form.Group>
        </Tab>

        {/* Address Tab */}
        <Tab eventKey="address" title="Address">
          <Form.Group as={Row} className="mt-5">
            <Form.Label column sm="2" className="mb-4">Landline</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="text"
                className="form-control"
                value={address.landline}
                onChange={(e) => setAddress({ ...address, landline: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Mobile</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="text"
                className="form-control"
                value={address.mobile}
                onChange={(e) => setAddress({ ...address, mobile: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Email 1</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="email"
                className="form-control"
                value={address.email1}
                onChange={(e) => setAddress({ ...address, email1: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Email 2</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="email"
                className="form-control"
                value={address.email2}
                onChange={(e) => setAddress({ ...address, email2: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">Address Line 1</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="text"
                className="form-control"
                value={address.addressLine1}
                onChange={(e) => setAddress({ ...address, addressLine1: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="mb-4">City</Form.Label>
            <Col sm="10" className="mb-4">
              <Form.Control
                type="text"
                className="form-control"
                value={address.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
              />
            </Col>
          </Form.Group>
          {/* Add remaining address fields here */}
        </Tab>
      </Tabs>
      <Button variant="primary" type="submit">Add Organization</Button>
    </Form>
  );
}

export default OrganizationForm;
