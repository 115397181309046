// src/App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Redirect, useLocation, useNavigate  } from 'react-router-dom';
// import Home from './components/Home';
// import Header from './components/Header';
import DashboardLayout from './components/DashboardLayout';
import FormBuilderView from './components/FormBuilderView';
import FormRenderer from './components/FormRenderer';  // A component for rendering forms
import FormsList from './components/FormsList';
import UserList from './components/UserList';
import AddRole from './components/AddRole';
import ListRoles from './components/ListRoles';
import EditFormBuilder from './components/EditFormBuilder';
import FormSubmissions from './components/FormSubmissions';
import Login from './components/Login';
import Register from './components/Register';
import OrganizationForm from './components/OrganizationForm';
import OrganizationTable from './components/OrganizationTable';
import OrganisationList from './components/OrganisationList';
import CommitteeList from './components/CommitteeList';
import DeliveryPartnerList from './components/DeliveryPartnerList';


function App() {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    // Check if the token exists in localStorage
    const token = localStorage.getItem('token');
    
    if (token) {
      setIsLoggedIn(true);
      //console.log('token present:'+ token);
      // Retrieve the username from localStorage
      const storedUsername = localStorage.getItem('username');     
      if (storedUsername) {
        setUsername(storedUsername);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    // Clear the token and username from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    navigate('/login');
  };
  return (  
   
      <div className="app">
     {/* Conditionally render the Header */}
            
      <Routes> 
      <Route path="/login"  element={<Login />}  />
      <Route path="/" element={<DashboardLayout username={username} handleLogout={handleLogout} />}>     
      <Route index element={<FormBuilderView />} />
      <Route path="/forms" element={<FormsList />} />
      <Route path="/forms/:index" element={<FormRenderer />} /> {/* Add Route for single form */}
      <Route path="/edit-form/:index" element={<EditFormBuilder />} /> {/* Add Edit Form Route */}
      <Route path="/submissions/:index" element={<FormSubmissions />} /> {/* Add route for submissions */}     
      <Route path="/register" element={<Register />} />
      <Route path="/add-role" element={<AddRole />} />
      <Route path="/list-roles" element={<ListRoles />} />
      <Route path="/user-list" element={<UserList />} />
      <Route path="/organizations" element={<OrganisationList />} />
      <Route path="/add-organization" element={<OrganizationForm />} />
      <Route path="/committees" element={<CommitteeList />} />
      <Route path="/delivery-partners" element={<DeliveryPartnerList />} />
      </Route>
      </Routes>
      </div>    
     );
  
}

// App Wrapper to include Router
export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
