// src/components/FormSubmissions.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFormSubmissionsFromLocal, getFormsFromLocal } from '../utils/formUtils';

const FormSubmissions = () => {
  const { index } = useParams(); // Get the form index from the URL parameters
  const [submissions, setSubmissions] = useState([]);
  const forms = getFormsFromLocal();
  const formName = forms[index]?.name || `Form ${index + 1}`; // Get the form name

  useEffect(() => {
    const savedSubmissions = getFormSubmissionsFromLocal(index);
    setSubmissions(savedSubmissions);
  }, [index]);

  return (
    <div className="container">
      <h2>Submissions for {formName}</h2>
      {submissions.length === 0 ? (
        <p>No submissions yet.</p>
      ) : (
        <ul>
          {submissions.map((submission, submissionIndex) => (
            <li key={submissionIndex}>
              <pre>{JSON.stringify(submission.data, null, 2)}</pre>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormSubmissions;
