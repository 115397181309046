import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

const ListRoles = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch roles from the API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/roles`); // Fetch roles from the backend
        setRoles(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching roles', error);
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  // Define columns for the DataTable
  const columns = [
    {
        name: '#', // Serial number column
        selector: (row, index) => index + 1, // Using index + 1 to show the serial number
        sortable: false,
        width: '60px' // Adjust width of serial number column
      },
    {
      name: 'Role Name',
      selector: (row) => row.roleName,
      sortable: true
    },
    {
      name: 'Permissions',
      selector: (row) => row.permissions.join(', '), // Display permissions as a comma-separated string
      sortable: false
    }
  ];

  return (
    <div>
      <h2>List of Roles</h2>
      <DataTable
        title="Roles"
        columns={columns}
        data={roles}
        progressPending={loading}
        pagination // Add pagination to the table
      />
    </div>
  );
};

export default ListRoles;
